<template>
  <v-container fluid>
    <v-card>
        <v-card-title>
            Reset Password
        </v-card-title>
        <v-card-text>
          <v-text-field v-model="currentPassword" type="password" label="Current Password"></v-text-field>
          <v-text-field v-model="password" type="password" label="New Password"></v-text-field>
          <v-text-field v-model="confirmPassword" type="password" label="Confirm New Password"></v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-btn :disabled="isAppLoading" @click="resetPassword()">Update</v-btn>
        </v-card-actions>
    </v-card>
  </v-container>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  data(){
    return {
      currentPassword: '',
      password: '',
      confirmPassword: ''
    }
  },
  computed:{
    ...mapGetters("auth", ["isLoggedIn", "currentUser"]),
  },
  methods:{
    resetPassword(){
      const resetData = {
        currentPassword: this.currentPassword,
        password: this.password,
        confirmPassword: this.confirmPassword
      }

      this.$axios.post('/customer/reset/password', resetData)
        .then( (resp) => {
          const customerName = resp.data.firstname;
          this.$store.dispatch('messages/addMessage', {message: `${customerName}, your password has changed`, messageClass: 'success'})
          this.$router.push('/');
        })
        .catch( (error) => {
            const message = error.data.message || 'Error reseting your password';
            this.$store.dispatch('messages/addMessage', {message, messageClass: 'danger'})
        })
    }
  }

};
</script>

<style>
</style>